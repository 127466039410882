import React from 'react'
import { Link } from 'react-router-dom'
import '../CSS/DonationButton.css'

const DonationButton = () => {
    return (
        <Link to='/donner'>
            <div className='donate-button'>
                <button>
                    <div className='donate-button-info'>
                        <span className="material-symbols-rounded">
                            volunteer_activism
                        </span>
                        <p>Faire un don</p>
                    </div>

                </button>
            </div>
        </Link>
    )
}

export default DonationButton