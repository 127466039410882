import React from 'react'
import '../CSS/Connexion.css'
import { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router';
import Footer from '../Components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setToken } from '../Helpers/Store';

axios.defaults.withCredentials = true;

const Connexion = (props) => {

    const [user, setUser] = useState("")
    const [pwd, setPwd] = useState("")

    const [loginStatus, setLoginStatus] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch();

    useEffect(() => {
    }, [loginStatus]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + "/login", {
                username: user,
                password: pwd,
            })

            if (res.data.auth) {
                setLoginStatus(true);
                setPwd()
                setUser()
                dispatch(setToken(res.data.token));
                //props.setToken(res.data.token)
                navigate("/admin")
                toast.success(res.data.message, {
                    toastId: 'Success',
                    icon: ({ theme, type }) => <img src="./gif/cat-happy.gif" className='toast-icon' />,
                })
            } else {
                setLoginStatus(false);
                toast.error(res.data.message, {
                    toastId: 'Error',
                    icon: ({ theme, type }) => <img src="./gif/sad-cat.gif" className='toast-icon' />,
                })
            }
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <div className={props.launched ? "login" : "login-not-launched"}>
            <div className="container">
                <div className="left">
                    <div className="login-title">
                        <h1>Connexion</h1>
                    </div>
                </div>
                <div className="right">
                    <form className="form" onSubmit={handleSubmit}>
                        <div className="form-username">
                            <input type="text" name="text" autoComplete="off" onChange={(e) => setUser(e.target.value)} value={user} required />
                            <label htmlFor="text" className="label-username">
                                <span className="content-name">
                                    Identifiant
                                </span>
                            </label>
                        </div>
                        <div className="form-password">
                            <input type="password" name="password" autoComplete="off" onChange={(e) => setPwd(e.target.value)} value={pwd} required />
                            <label htmlFor="password" className="label-password">
                                <span className="content-name">
                                    Mot de passe
                                </span>
                            </label>
                        </div>
                        <div className="submit">
                            <div className="submit-button">
                                <button type='submit' className="btn"><span>Connexion</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Connexion