import React from 'react'
import {
    useNavigate,
} from 'react-router-dom';
import '../CSS/GoBackButton.css'

const GoBackButton = (props) => {

    const navigate = useNavigate();

    const goBack = () => {
        navigate(props.location);
    };

    return (
        <div className='GoBackButton'>
            <button onClick={goBack} className='back-button'>
                <span className="material-symbols-rounded">
                    arrow_back
                </span>
            </button>
        </div>
    )
}

export default GoBackButton