import React from 'react'
import '../CSS/CGU.css'
import Footer from './Footer'
import GoBackButton from './GoBackButton'

const CGU = () => {
    return (
        <div className='CGU'>
            <GoBackButton location={-1}></GoBackButton>
            <div className='CGU-container'>
                <div className='CGU-title'>
                    <h1>Conditions générales d'utilisation</h1>
                    <br />
                    <p>En vigueur au 27/05/2023</p>
                </div>
                <div className='CGU-header'>
                    <br />
                    <p>Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par Les Bibichoux de St Martin  et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».
                        Les présentes CGU sont accessibles sur le site à la rubrique «CGU».</p>
                </div>
                <div className='CGU-article'>
                    <br />
                    <h2>Article 1 : Les mentions légales</h2>
                    <p>L'édition du site www.lesbibichouxdestmartin.fr est assurée par la Société Association loi 1901 Les Bibichoux de St Martin au capital de 0 euros, immatriculée au RCS de Toulouse sous le numéro 921255972, dont le siège social est situé au 6 Rue Henri Ziegler
                        Numéro de téléphone 0674227788
                        Adresse e-mail : lesbibichouxdestmartin@gmail.com.
                        Le Directeur de la publication est : NEYRAS Valérie</p>
                    <p>L'hébergeur du site www.lesbibichouxdestmartin.fr est la société IONOS, dont le siège social est situé au 7 place de la Gare 57200 Sarreguemines, avec le numéro de téléphone : 0970808911.</p>
                </div>
                <div className='CGU-article'>
                    <br />
                    <h2>ARTICLE 2 : Accès au site</h2>
                    <p>Le site internet propose les services suivants :<br />
                        Protection et défense des animaux, prise en charge des animaux abandonnés prioritairement des chats ou chatons, stérilisation des chats de rue, placement des animaux au sein de famille d'accueils ou d'adoptants, participer a la sensibilisation de la cause animale auprès de l'opinion publique, soutenir et conduire toutes actions visant au respect et à la défense des animaux
                        Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.</p>
                </div>
                <div className='CGU-article'>
                    <br />
                    <h2>ARTICLE 3 : Collecte des données</h2>
                    <p>Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il ne collecte aucune donnée concernant les Utilisateurs.</p>
                </div>
                <div className='CGU-article'>
                    <br />
                    <h2>ARTICLE 4 : Propriété intellectuelle</h2>
                    <p>Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.<br />
                        L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.<br />
                        Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.<br />
                        Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.</p>
                </div>
                <div className='CGU-article'>
                    <br />
                    <h2>ARTICLE 5 : Responsabilité</h2>
                    <p>Les sources des informations diffusées sur le site www.lesbibichouxdestmartin.fr sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.<br />
                        Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site www.lesbibichouxdestmartin.fr ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.<br />
                        Le site www.lesbibichouxdestmartin.fr ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site.<br />
                        La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.</p>
                </div>
                <div className='CGU-article'>
                    <br />
                    <h2>ARTICLE 6 : Liens hypertextes</h2>
                    <p>Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site www.lesbibichouxdestmartin.fr. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.</p>
                </div>
                <div className='CGU-article'>
                    <br />
                    <h2>ARTICLE 7 : Cookies</h2>
                    <p>L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.<br />
                        Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site www.lesbibichouxdestmartin.fr. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.<br />
                        L’information contenue dans les cookies est utilisée pour améliorer le site www.lesbibichouxdestmartin.fr.<br />
                        En naviguant sur le site, L’Utilisateur les accepte.<br />
                        L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.</p>
                </div>
                <div className='CGU-article'>
                    <br />
                    <h2>ARTICLE 8 : Droit applicable et juridiction compétente</h2>
                    <p>La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.<br/>
                        Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.</p>
                </div>
                <div className='CGU-credit'>
                    <br />
                    <p>CGU réalisées sur http://legalplace.fr/</p>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default CGU