import React from 'react'
import ContentLoader from "react-content-loader";
import '../CSS/ChatInfoPlaceholder.css'

const ChatInfoPlaceholder = () => {
    const mystyle = {
        display: "flex",
        justifyContent: "center",
        textAlign: 'center'
    };

    return (
        <div className='chatInfoPlaceholder'>
            <div className='placeholder-desktop'>
                <ContentLoader
                    width={"100%"}
                    height={"calc(100dvh - 410px)"}
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                    backgroundColor="#f5f5f5"
                    foregroundColor="#dbdbdb"
                >
                    <rect x="10%" y="15%" rx="0" ry="0" width="30%" height="70%" />
                    <rect x="60%" y="0" rx="0" ry="0" width="30%" height="10%" />


                    <rect x="50%" y="25%" rx="0" ry="0" width="40%" height="2%" />
                    <rect x="50%" y="30%" rx="0" ry="0" width="45%" height="2%" />
                    <rect x="50%" y="35%" rx="0" ry="0" width="50%" height="2%" />
                    <rect x="50%" y="40%" rx="0" ry="0" width="40%" height="2%" />
                    <rect x="50%" y="45%" rx="0" ry="0" width="45%" height="2%" />
                    <rect x="50%" y="50%" rx="0" ry="0" width="50%" height="2%" />
                    <rect x="50%" y="55%" rx="0" ry="0" width="40%" height="2%" />
                    <rect x="50%" y="60%" rx="0" ry="0" width="45%" height="2%" />
                    <rect x="50%" y="65%" rx="0" ry="0" width="50%" height="2%" />
                    <rect x="50%" y="75%" rx="0" ry="0" width="20%" height="10%" />
                </ContentLoader>
            </div>
            <div className='placeholder-mobile'>
                <ContentLoader
                    width={"100%"}
                    height={"calc(100dvh - 170px)"}
                    viewBox="0 0 100 100" 
                    preserveAspectRatio="none"
                    backgroundColor="#f5f5f5"
                    foregroundColor="#dbdbdb"
                >
                    <rect x="30%" y="0" rx="0" ry="0" width="40%" height="15%" />
                    <rect x="10%" y="20%" rx="0" ry="0" width="80%" height="30%" />

                    <rect x="10%" y="55%" rx="0" ry="0" width="70%" height="2%" />
                    <rect x="10%" y="60%" rx="0" ry="0" width="75%" height="2%" />
                    <rect x="10%" y="65%" rx="0" ry="0" width="80%" height="2%" />
                    <rect x="10%" y="70%" rx="0" ry="0" width="70%" height="2%" />
                    <rect x="10%" y="75%" rx="0" ry="0" width="75%" height="2%" />
                    <rect x="10%" y="80%" rx="0" ry="0" width="80%" height="2%" />
                    <rect x="30%" y="90%" rx="0" ry="0" width="40%" height="10%" />
                </ContentLoader>
            </div>
            <div className='placeholder-tablet'>
                <ContentLoader
                    width={"100%"}
                    height={"calc(100dvh - 260px)"}
                    viewBox="0 0 100 100" 
                    preserveAspectRatio="none"
                    backgroundColor="#f5f5f5"
                    foregroundColor="#dbdbdb"
                >
                    <rect x="20%" y="5%" rx="0" ry="0" width="60%" height="10%" />
                    <rect x="10%" y="20%" rx="0" ry="0" width="80%" height="30%" />

                    <rect x="10%" y="60%" rx="0" ry="0" width="75%" height="2%" />
                    <rect x="10%" y="65%" rx="0" ry="0" width="80%" height="2%" />
                    <rect x="10%" y="70%" rx="0" ry="0" width="70%" height="2%" />
                    <rect x="10%" y="75%" rx="0" ry="0" width="75%" height="2%" />
                    <rect x="10%" y="80%" rx="0" ry="0" width="80%" height="2%" />
                    <rect x="10%" y="85%" rx="0" ry="0" width="70%" height="2%" />
                    <rect x="30%" y="90%" rx="0" ry="0" width="40%" height="7%" />
                </ContentLoader>
            </div>
        </div>
    )
}

export default ChatInfoPlaceholder