import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import '../CSS/Loader.css'

const Loader = () => {
    return (
        <div className='loader'>
            <div className='loader-spinner'>
                <img className='img-logo' src='/images/logo.png' alt='logo' />
                <img className='img-bandeau' src='/images/bandeau.png' alt='bandeau' />
            </div>
            <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="black"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    )
}

export default Loader