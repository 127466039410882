import React from 'react'
import { Link } from 'react-router-dom'
import '../CSS/Footer.css'

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-info'>
                <ul>
                    <li><Link to="/cgu"><p>CGU</p></Link></li>
                    <li><Link to="/faq"><p>FAQ</p></Link></li>
                    <li><Link to="/contact"><p>Contact</p></Link></li>
                </ul>
            </div>
            <div className='footer-social'>
                <ul>
                    <li><a href="https://www.facebook.com/profile.php?id=100087436623565" target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                    <li><a href="https://www.instagram.com/lesbibichouxdestmartin/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                    <li><a href='https://www.tiktok.com/@lesbibichouxdestmartin' target="_blank"><i className="fa-brands fa-tiktok" aria-hidden="true"></i></a></li>
                </ul>
            </div>
            <div className='footer-copyright'>
                <p>© Les Bibichoux De St. Martin - Copyright 2023</p>
            </div>
        </footer>
    )
}

export default Footer