import React, { useEffect, useMemo, useState } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Components/Home';
import DonationForm from './Components/DonationForm';
import Chats from './Components/Chats';
import FormAddChat from './Components/FormAddChat';
import AdminChats from './Components/AdminChats';
import axios from 'axios';
import PageNotFound from './Components/PageNotFound';
import Navbar from './Components/Navbar';
import ChatInfo from './Components/ChatInfo';
import Association from './Components/Association';
import DonationButton from './Components/DonationButton';
import Connexion from './Components/Connexion';
import FormUpdateChat from './Components/FormUpdateChat';
import Footer from './Components/Footer';
import ChatsAdopte from './Components/ChatsAdopte';
import FAQ from './Components/FAQ';
import DonationPage from './Components/DonationPage';
import AdherentForm from './Components/AdherentForm';
import BoutiqueForm from './Components/BoutiqueForm';
import Contact from './Components/Contact';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './Helpers/ScrollToTop';
import FamilleAccueil from './Components/FamilleAccueil';
import Documents from './Components/Documents';
import ComingSoon from './Components/ComingSoon';

import { useDispatch, useSelector } from 'react-redux';
import { setToken } from './Helpers/Store';
import CGU from './Components/CGU';


function App() {
  const [idChat, setIdChat] = useState(null)
  const [launched, setLaunched] = useState(false)

  const token = useSelector(state => state.auth.token);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const res = await axios.get(process.env.REACT_APP_API_URL + "/token")
      dispatch(setToken(res.data.token))
    }
    fetchData();
  }, [token]);

  useEffect(() => {
    //console.log(launched);
    //console.log(token);
  }, [launched]);

  return (
    <div className="App">

      {!launched && !token ?
        <Router>
          <ToastContainer draggable={false} />
          <ScrollToTop></ScrollToTop>
          <Routes>
            <Route exact path="/" element={<ComingSoon></ComingSoon>} />
            <Route exact path="*" element={<ComingSoon></ComingSoon>} />
            <Route exact path="/login" element={<Connexion launched={launched}></Connexion>} />
          </Routes>
        </Router>
        :
        <Router>
          <ToastContainer draggable={false} />
          <Navbar token={token}></Navbar>
          <ScrollToTop></ScrollToTop>
          <Routes>
            <Route exact path="/" element={<Home></Home>} />
            <Route exact path="/documents" element={<Documents></Documents>} />
            <Route exact path="/soutenir" element={<DonationPage></DonationPage>} />
            <Route exact path="/donner" element={<DonationForm></DonationForm>} />
            <Route exact path="/adherer" element={<AdherentForm></AdherentForm>} />
            <Route exact path="/boutique" element={<BoutiqueForm></BoutiqueForm>} />
            <Route exact path="/asso" element={<Association></Association>} />
            <Route exact path="/asso/adopte" element={<ChatsAdopte></ChatsAdopte>} />
            <Route exact path="/adopter" element={<Chats idChat={id => setIdChat(id)}></Chats>} />
            <Route exact path="/adopter/:idChat" element={<ChatInfo token={token} id={idChat}></ChatInfo>} />
            <Route exact path="/faq" element={<FAQ></FAQ>} />
            <Route exact path="/cgu" element={<CGU></CGU>} />
            <Route exact path="/contact" element={<Contact></Contact>} />
            <Route exact path="/chat" element={<ChatInfo></ChatInfo>} />
            <Route exact path="/famille" element={<FamilleAccueil></FamilleAccueil>} />

            {!token ? <Route exact path="/login" element={<Connexion launched={launched}></Connexion>} /> : <Route exact path="/login" element={<AdminChats token={token}></AdminChats>} />}

            <Route exact path="*" element={<PageNotFound></PageNotFound>} />

            {token && <Route exact path="/admin" element={<AdminChats token={token} idChat={id => setIdChat(id)}></AdminChats>} />}
            {token && <Route exact path="/add" element={<FormAddChat></FormAddChat>} />}
            {token && <Route exact path="/update/:idChat" element={<FormUpdateChat id={idChat}></FormUpdateChat>} />}
          </Routes>
          <DonationButton></DonationButton>
        </Router>
      }
    </div >
  );
}

export default App;
