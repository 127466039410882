import React, { useState } from 'react'
import '../CSS/FormAddChat.css'
import axios from 'axios'
import { useNavigate } from 'react-router'
import Footer from './Footer'
import GoBackButton from './GoBackButton'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormAddChat = () => {

    axios.defaults.withCredentials = true;

    const [file, setFile] = useState([])
    const [multipleImages, setMultipleImages] = useState([]);
    const [chat, setChat] = useState({
        nom: "",
        sexe: "",
        dtn: "",
        pelage: "",
        description: "",
        visibilite: ""
    })

    const robeTab = ["-- Solide --", "Noir solide", "Blanc", "Bleu solide", "Roux solide", "Lilas solide", "-- Bicolore --", "Noir et blanc", "Bleu et blanc", "Roux et blanc", "Tortie", "-- Tricolore --", "Tortie et blanc", "-- Tabby --", "Brun tabby", "Bleu tabby", "Roux tabby", "Crème tabby", "Argentée tabby", "Lilas tabby", "Tortie tabby", "Tortie tabby et blanc", "Bleu tabby et blanc", "Roux tabby et blanc", "Brun tabby et blanc", "-- Colorpoint --", "Seal point", "Bleu point", "Roux point", "Lilas point", "Caramel point", "Crème point", "Chocolat point", "Seal tabby point", "Bleu tabby point", "Lilas tabby point", "Roux tabby point et blanc"]

    const navigate = useNavigate()

    const handleChange = (e) => {
        if (e.target.name !== "image") {
            setChat((prev) => ({ ...prev, [e.target.name]: e.target.value }))
        } else {
            setFile(e.target.files)
            const imageArray = Array.from(e.target.files).map((file) =>
                URL.createObjectURL(file)
            );
            setMultipleImages(imageArray);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = new FormData()
        for (let i = 0; i < file.length; i++) {
            data.append("image", file[i]);
        }

        for (let element in chat) {
            data.append(element, chat[element])
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + "/chats", data, config)
            if (res.data.status === 201) {
                navigate("/admin")
                toast.success(res.data.message, {
                    toastId: 'Success',
                    icon: ({ theme, type }) => <img src="./gif/cat-happy.gif" className='toast-icon' />,
                })
            } else {
                toast.error(res.data.message, {
                    toastId: 'Error',
                    icon: ({ theme, type }) => <img src="./gif/sad-cat.gif" className='toast-icon' />,
                })
            }

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div className='add'>
            <GoBackButton location='/admin'></GoBackButton>
            <form className='form-add-chat' onSubmit={handleSubmit} encType="multipart/form-data">
                <div className='form-title'>
                    <h1>Ajouter un chat</h1>
                </div>
                <div className='form-input-nom'>
                    <div className='nom-label'>
                        <span className="material-symbols-rounded">
                            badge
                        </span>
                        <label htmlFor='nom'><p>Nom :</p></label>
                    </div>
                    <input type='text' placeholder='Nom' name='nom' id='nom' onChange={handleChange}></input>
                </div>
                <div className='form-input-sexe'>
                    <div className='sexe-label'>
                        <span className="material-symbols-rounded">
                            male
                        </span>
                        <span className="material-symbols-rounded">
                            female
                        </span>
                        <label htmlFor='form-input-sexe'><p>Sexe :</p></label>
                    </div>
                    <div>
                        <div>
                            <input type="radio" id="male" name="sexe" value="M" onChange={handleChange} />
                            <label htmlFor="male"><p>Male</p></label>
                        </div>
                        <div>
                            <input type="radio" id="femelle" name="sexe" value="F" onChange={handleChange} />
                            <label htmlFor="femelle"><p>Femelle</p></label>
                        </div>
                    </div>
                </div>
                <div className='form-input-dtn'>
                    <div className='dtn-label'>
                        <span className="material-symbols-rounded">
                            calendar_month
                        </span>
                        <label htmlFor='dtn'><p>Date de naissance :</p></label>
                    </div>
                    <input type="date" name="dtn" onChange={handleChange} />
                </div>
                <div className='form-input-pelage'>
                    <div className='pelage-label'>
                        <span className="material-symbols-rounded">
                            mystery
                        </span>
                        <label htmlFor="pelage"><p>Choisir une apparence :</p></label>
                    </div>
                    <select name="pelage" id="pelage" onChange={handleChange} defaultValue="Choisir une apparence">
                        <option disabled>Choisir une apparence</option>
                        {
                            robeTab.map((robe, index) => {
                                if (robe === "-- Solide --" || robe === "-- Bicolore --" || robe === "-- Tricolore --" || robe === "-- Tabby --" || robe === "-- Colorpoint --") {
                                    return <option key={index} value={robe} disabled>{robe}</option>
                                } else {
                                    return <option key={index} value={robe}>{robe}</option>
                                }
                            })
                        }
                    </select>
                </div>
                <div className='form-input-description'>
                    <div className='description-label'>
                        <span className="material-symbols-rounded">
                            description
                        </span>
                        <label htmlFor="description"><p>Description :</p></label>
                    </div>
                    <textarea id="description" name="description" rows="5" cols="33" placeholder='Description' onChange={handleChange}></textarea>
                </div>
                <div className='form-input-visibility'>
                    <div className='visibility-label'>
                        <span className="material-symbols-rounded">
                            visibility
                        </span>
                        <label htmlFor='form-input-visibility'><p>Visibilité :</p></label>
                    </div>
                    <div>
                        <div>
                            <input type="radio" id="adoption" name="visibilite" value="adoption" onChange={handleChange} />
                            <label htmlFor="adoption"><p>A l'adoption</p></label>
                        </div>
                        <div>
                            <input type="radio" id="adopte" name="visibilite" value="adopte" onChange={handleChange} />
                            <label htmlFor="adopte"><p>Adopté</p></label>
                        </div>
                        <div>
                            <input type="radio" id="masque" name="visibilite" value="masque" onChange={handleChange} />
                            <label htmlFor="masque"><p>Masqué</p></label>
                        </div>
                    </div>
                </div>
                <div className='form-input-image' name='images'>
                    <div className='image-label'>
                        <span className="material-symbols-rounded">
                            image
                        </span>
                        <label htmlFor="image"><p>Images (max 5) :</p></label>
                    </div>
                    <div className='previewImage-add'>
                        {
                            multipleImages && multipleImages.map((url, index) => (
                                <div key={index}>
                                    <img src={url} />
                                </div>
                            ))
                        }
                    </div>
                    <input type="file" multiple='multiple' id="image" name="image" accept="image/png, image/jpeg, image/jpg" onChange={handleChange} hidden />
                    <label htmlFor='image'>
                        <span className="material-symbols-rounded">
                            upload_file
                        </span>
                        <p>Choisissez un image</p>
                    </label>
                </div>
                <div className='form-input-send'>
                    <input type="submit" value="Ajouter le chat" />
                </div>
            </form>
            <Footer></Footer>
        </div>
    )
}

export default FormAddChat