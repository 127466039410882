import axios from 'axios'
import React, { useEffect, useState } from 'react'
import '../CSS/ChatAdopte.css'
import { v4 as uuid } from "uuid";

const ChatAdopte = (props) => {

    const [imgName, setImgName] = useState(null)

    const keyID = uuid()

    const getImage = async (id) => {
        const res3 = await axios.get(process.env.REACT_APP_API_URL + `/images/${props.info.id}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (res3.status === 200) {
            setImgName(res3.data[0].image)
        }
    }

    useEffect(() => {
        getImage(props.info.id)
    }, [])

    return (
        <div key={keyID} className='chat-adopte'>
            <div className='chat-img'>
                {imgName && <img src={`/uploads/${imgName}`} className="img-back" alt="img" />}
                {imgName && <img src={`/uploads/${imgName}`} className="img-front" alt="img" />}
            </div>
            <div className='chat-description'>
                <div className='description'>
                    <h2>{props.info.name}</h2>
                </div>
            </div>
        </div>
    )
}

export default ChatAdopte