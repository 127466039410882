import React from 'react'
import '../CSS/PageNotFound.css'
import Footer from './Footer'
import GoBackButton from './GoBackButton'

const PageNotFound = () => {
  return (
    <div className='page-not-found-page'>
      <GoBackButton location={-1}></GoBackButton>
      <div className='page-not-found'>
        <img src='/gif/peach-cat-cute.gif' alt='gif-sorry'></img>
        <p>Désolé, nous n'avons pas trouvé la page que vous avez demandé.</p>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default PageNotFound