import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../CSS/FormUpdateChat.css'
import Footer from './Footer'
import GoBackButton from './GoBackButton'
import moment from 'moment'
import { useSelector } from 'react-redux'

const FormUpdateChat = () => {

    axios.defaults.withCredentials = true;

    const [chat, setChat] = useState(null)
    const [error, setError] = useState(false)
    const [chatFill, setChatFill] = useState(null)
    const [imgList, setImgList] = useState(null)
    const [file, setFile] = useState(null)
    const [multipleImages, setMultipleImages] = useState([]);
    const [selectedPelage, setSelectedPelage] = useState("")
    const [date, setDate] = useState(null)

    const navigate = useNavigate();
    const { idChat } = useParams()
    const token = useSelector(state => state.auth.token);

    const robeTab = ["-- Solide --", "Noir solide", "Blanc", "Bleu solide", "Roux solide", "Lilas solide", "-- Bicolore --", "Noir et blanc", "Bleu et blanc", "Roux et blanc", "Tortie", "-- Tricolore --", "Tortie et blanc", "-- Tabby --", "Brun tabby", "Bleu tabby", "Roux tabby", "Crème tabby", "Argentée tabby", "Lilas tabby", "Tortie tabby", "Tortie tabby et blanc", "Bleu tabby et blanc", "Roux tabby et blanc", "Brun tabby et blanc", "-- Colorpoint --", "Seal point", "Bleu point", "Roux point", "Lilas point", "Caramel point", "Crème point", "Chocolat point", "Seal tabby point", "Bleu tabby point", "Lilas tabby point", "Roux tabby point et blanc"]

    const getChat = async (id) => {
        const res = await axios.get(process.env.REACT_APP_API_URL + `/chatsAdmin/${id}`)
        if (res.data.status === 200) {
            const dateFetched = res.data.data[0].date_naiss;
            const dateTO = new Date(dateFetched)
            setDate(moment(dateTO).format('YYYY-MM-DD'));
            setChat(res.data.data[0])
        } else {
            setError(true)
        }
    }

    const getImage = async () => {
        const res3 = await axios.get(process.env.REACT_APP_API_URL + `/images/${idChat}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (res3.status === 200) {
            setImgList(res3.data)
        }
    }

    useEffect(() => {
        if (chat) {
            setSelectedPelage(chat.pelage)
        } else {
            getChat(idChat)
        }
        getImage()
    }, [chat])

    useEffect(() => {
    }, [chatFill, file, multipleImages])

    const handleChange = (e) => {
        if (e.target.name === "image") {
            setFile(e.target.files)
            const imageArray = Array.from(e.target.files).map((file) =>
                URL.createObjectURL(file)
            );
            setMultipleImages(imageArray);
        } else if (e.target.name === "pelage") {
            setSelectedPelage(e.target.value)
            setChatFill((prev) => ({ ...prev, [e.target.name]: e.target.value }))
        } else {
            setChatFill((prev) => ({ ...prev, [e.target.name]: e.target.value }))
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = new FormData()

        if (file) {
            for (let i = 0; i < file.length; i++) {
                data.append("image", file[i]);
            }
        }

        for (let element in chatFill) {
            data.append(element, chatFill[element])
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        try {
            const res = await axios.put(process.env.REACT_APP_API_URL + `/chats/${idChat}`, data, config)

            if (res.data.status === 201) {
                navigate("/admin")
                toast.success(res.data.message, {
                    toastId: 'Success',
                    icon: ({ theme, type }) => <img src="/gif/cat-happy.gif" className='toast-icon' />,
                })
            } else {
                toast.error(res.data.message, {
                    toastId: 'Error',
                    icon: ({ theme, type }) => <img src="/gif/sad-cat.gif" className='toast-icon' />,
                })
            }

        } catch (error) {
            console.log(error);
        }
    }

    const handleMoveTop = async () => {
        try {
            const res = await axios.put(process.env.REACT_APP_API_URL + `/chats/move/${idChat}`)

            if (res.data.status === 201) {
                toast.success(res.data.message, {
                    toastId: 'Success',
                    icon: ({ theme, type }) => <img src="/gif/cat-happy.gif" className='toast-icon' />,
                })
            } else {
                toast.error(res.data.message, {
                    toastId: 'Error',
                    icon: ({ theme, type }) => <img src="/gif/sad-cat.gif" className='toast-icon' />,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className='update'>
                <GoBackButton location='/admin'></GoBackButton>
                {error ? (
                    <div className='error-chat page-not-found'>
                        <img src='/gif/peach-cat-cute.gif' alt='gif-sorry'></img>
                        {
                            <p>Désolé, ce chat n'existe pas.</p>
                        }

                    </div>
                )
                    :
                    chat && (

                        <form className='form-update-chat' onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className='form-title'>
                                <h1>Modifier un chat</h1>
                            </div>
                            <div className='form-button-move-top'>
                                <button type='button' onClick={handleMoveTop}>
                                    <span className="material-symbols-rounded">
                                        arrow_upward
                                    </span>
                                    <p>Faire remonter</p></button>
                            </div>
                            <div className='form-input-nom'>
                                <div className='nom-label'>
                                    <span className="material-symbols-rounded">
                                        badge
                                    </span>
                                    <label htmlFor='nom'><p>Nom :</p></label>
                                </div>
                                <input type='text' placeholder='Nom' name='nom' id='nom' onChange={handleChange} defaultValue={chat.nom}></input>
                            </div>
                            <div className='form-input-sexe'>
                                <div className='sexe-label'>
                                    <span className="material-symbols-rounded">
                                        male
                                    </span>
                                    <span className="material-symbols-rounded">
                                        female
                                    </span>
                                    <label htmlFor='form-input-sexe'><p>Sexe :</p></label>
                                </div>
                                <div>
                                    <div>
                                        <input type="radio" id="male" name="sexe" value="M" onChange={handleChange} defaultChecked={chat.sexe === 'M' ? true : false} />
                                        <label htmlFor="male"><p>Male</p></label>
                                    </div>
                                    <div>
                                        <input type="radio" id="femelle" name="sexe" value="F" onChange={handleChange} defaultChecked={chat.sexe === 'F' ? true : false} />
                                        <label htmlFor="femelle"><p>Femelle</p></label>
                                    </div>
                                </div>
                            </div>
                            <div className='form-input-dtn'>
                                <div className='dtn-label'>
                                    <span className="material-symbols-rounded">
                                        calendar_month
                                    </span>
                                    <label htmlFor='dtn'><p>Date de naissance :</p></label>
                                </div>
                                <input type="date" name="date_naiss" onChange={handleChange} defaultValue={date} />
                            </div>
                            <div className='form-input-pelage'>
                                <div className='pelage-label'>
                                    <span className="material-symbols-rounded">
                                        mystery
                                    </span>
                                    <label htmlFor="pelage"><p>Choisir une apparence :</p></label>
                                </div>
                                <select name="pelage" id="pelage" onChange={handleChange} value={selectedPelage}>
                                    {
                                        robeTab.map((robe, index) => {
                                            if (robe === "-- Solide --" || robe === "-- Bicolore --" || robe === "-- Tricolore --" || robe === "-- Tabby --" || robe === "-- Colorpoint --") {
                                                return <option key={index} value={robe} disabled>{robe}</option>
                                            } else {
                                                return <option key={index} value={robe}>{robe}</option>
                                            }
                                        })
                                    }
                                </select>
                            </div>
                            <div className='form-input-description'>
                                <div className='description-label'>
                                    <span className="material-symbols-rounded">
                                        description
                                    </span>
                                    <label htmlFor="description"><p>Description :</p></label>
                                </div>
                                <textarea id="description" name="description" rows="5" cols="33" onChange={handleChange} defaultValue={chat.description}></textarea>
                            </div>
                            <div className='form-input-visibility'>
                                <div className='visibility-label'>
                                    <span className="material-symbols-rounded">
                                        visibility
                                    </span>
                                    <label htmlFor='form-input-visibility'><p>Visibilité :</p></label>
                                </div>
                                <div>
                                    <div>
                                        <input type="radio" id="adoption" name="visibilite" value="adoption" onChange={handleChange} defaultChecked={chat.adopte === 0 && chat.visible === 1 ? true : false} />
                                        <label htmlFor="adoption"><p>A l'adoption</p></label>
                                    </div>
                                    <div>
                                        <input type="radio" id="adopte" name="visibilite" value="adopte" onChange={handleChange} defaultChecked={chat.adopte === 1 ? true : false} />
                                        <label htmlFor="adopte"><p>Adopté</p></label>
                                    </div>
                                    <div>
                                        <input type="radio" id="masque" name="visibilite" value="masque" onChange={handleChange} defaultChecked={chat.adopte === 0 && chat.visible === 0 ? true : false} />
                                        <label htmlFor="masque"><p>Masqué</p></label>
                                    </div>
                                </div>
                            </div>
                            <div className='form-input-image' name='images'>
                                <div className='image-label'>
                                    <span className="material-symbols-rounded">
                                        image
                                    </span>
                                    <label htmlFor="image"><p>Images (max 5) :</p></label>
                                </div>
                                <div className='previewImage'>
                                    {!file || file.length === 0 ?
                                        imgList && imgList.map((fadeImage, index) => (
                                            <div key={index}>
                                                <img src={`/uploads/${fadeImage.image}`} />
                                            </div>
                                        ))
                                        :
                                        multipleImages && multipleImages.map((url, index) => (
                                            <div key={index}>
                                                <img src={url} />
                                            </div>
                                        ))
                                    }
                                </div>
                                <input type="file" id="image" name="image" accept="image/png, image/jpeg" onChange={handleChange} multiple hidden />
                                <label htmlFor='image'>
                                    <span className="material-symbols-rounded">
                                        upload_file
                                    </span>
                                    <p>Choisissez un image</p>
                                </label>
                            </div>
                            <div className='form-input-send'>
                                <input type="submit" value="Modifier le chat" />
                            </div>
                        </form>

                    )}
                <Footer></Footer>
            </div>
        </>
    )
}

export default FormUpdateChat