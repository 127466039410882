import { configureStore, createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null
    },
    reducers: {
        setToken(state, action) {
            state.token = action.payload;
        },
        clearToken(state) {
            state.token = null;
        }
    }
});

const store = configureStore({
    reducer: {
        auth: authSlice.reducer
    }
});

export const { setToken, clearToken } = authSlice.actions;

export default store;