import React, { useEffect } from 'react'
import Footer from './Footer'
import '../CSS/Documents.css'
import GoBackButton from './GoBackButton'

const Documents = () => {

    const files = ["Bulletin-Adhesion.pdf", "Certificat Engagement 2022.pdf", "Livret FA 2022.pdf", "CGU.pdf"]

    return (
        <div className='documents'>
            <GoBackButton location='/'></GoBackButton>
            <div className='documents-container'>
                <h1>Documents</h1>
                <p>Vous trouverez ici tous les documents téléchargeables :</p>
                <div className='documents-list'>
                    {
                        files.map((file, index) => {
                            return (
                                <div key={index} className='document'>
                                    <div className='document-button'>
                                        <a href={process.env.PUBLIC_URL + '/pdf/' + file} download>
                                            <button>
                                                <span className="material-symbols-rounded">
                                                    download
                                                </span>
                                                <p>{file}</p>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Documents