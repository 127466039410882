import React, { useEffect, useMemo, useState } from 'react'
import { Steps, Panel, Placeholder, ButtonGroup, Button } from 'rsuite';
import "rsuite/dist/rsuite.css";

const StepsProcess = (props) => {

    const [step, setStep] = useState(0);
    const [currentText, setCurrentText] = useState(null)

    const processAdoption = [
        {
            id: 0,
            title: "Etape 1",
            description: "Remplir le formulaire d'adoption",
            text: "1Nunc vero inanes flatus quorundam vile esse quicquid extra urbis pomerium nascitur aestimant praeter orbos et caelibes, nec credi potest qua obsequiorum diversitate coluntur homines sine liberis Romae"
        },
        {
            id: 1,
            title: "Etape 2",
            description: "Validation du formulaire par l'association",
            text: "2Nunc vero inanes flatus quorundam vile esse quicquid extra urbis pomerium nascitur aestimant praeter orbos et caelibes, nec credi potest qua obsequiorum diversitate coluntur homines sine liberis Romae"
        },
        {
            id: 2,
            title: "Etape 3",
            description: "Prendre RDV avec la FA pour une visite",
            text: "3Nunc vero inanes flatus quorundam vile esse quicquid extra urbis pomerium nascitur aestimant praeter orbos et caelibes, nec credi potest qua obsequiorum diversitate coluntur homines sine liberis Romae"
        },
        {
            id: 3,
            title: "Etape 4",
            description: "Visite du chat chez la FA",
            text: "4Nunc vero inanes flatus quorundam vile esse quicquid extra urbis pomerium nascitur aestimant praeter orbos et caelibes, nec credi potest qua obsequiorum diversitate coluntur homines sine liberis Romae"
        },
        {
            id: 4,
            title: "Etape 5",
            description: "Validation de la visite par l'association",
            text: "5Nunc vero inanes flatus quorundam vile esse quicquid extra urbis pomerium nascitur aestimant praeter orbos et caelibes, nec credi potest qua obsequiorum diversitate coluntur homines sine liberis Romae"
        },
        {
            id: 5,
            title: "Etape 6",
            description: "Adoption",
            text: "6Nunc vero inanes flatus quorundam vile esse quicquid extra urbis pomerium nascitur aestimant praeter orbos et caelibes, nec credi potest qua obsequiorum diversitate coluntur homines sine liberis Romae"
        }
    ]

    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > 5 ? 5 : nextStep);
    };

    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);

    useEffect(() => {
        setCurrentText(processAdoption.filter((process) => process.id === step)[0].text);
    }, [step])

    return (
        <div>
            {(props.vertical === true) ?
                <Steps vertical current={step}>
                    {
                        processAdoption.map((step) => (
                            <Steps.Item key={step.id} title={step.title} description={step.description} />
                        ))
                    }
                </Steps>
                :
                <Steps current={step}>
                    {
                        processAdoption.map((step) => (
                            <Steps.Item key={step.id} title={step.title} description={step.description} />
                        ))
                    }
                </Steps>
            }

            <Panel header={`Etape ${step + 1}`}>
                <p>{currentText}</p>
            </Panel>
            <ButtonGroup>
                <Button onClick={onPrevious} disabled={step === 0}>
                    Etape précédente
                </Button>
                <Button onClick={onNext} disabled={step === 5}>
                    Etape suivante
                </Button>
            </ButtonGroup>
        </div>
    )
}

export default StepsProcess