import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../CSS/Navbar.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux';
import { clearToken, setToken } from '../Helpers/Store';

const Navbar = (props) => {

    //const [token, setToken] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);

    const navigate = useNavigate()
    const refNavBar = useRef([]);

    const token = useSelector(state => state.auth.token);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setToken(token));
        Unchecked()
    }, [token])


    const handleOptionChange = (option) => {
        if (selectedOption === option) {
            setSelectedOption(null);
        } else {
            setSelectedOption(option);
        }
    };

    const loggedOut = () => {
        axios.get(process.env.REACT_APP_API_URL + "/logout")
            .then((response) => {
                if (response.data.status === 201) {
                    dispatch(clearToken());
                    navigate("/")
                    toast.success(response.data.message, {
                        toastId: 'Success',
                        icon: ({ theme, type }) => <img src="./gif/cat-happy.gif" className='toast-icon' />,
                    })
                } else {
                    toast.error(response.data.message, {
                        toastId: 'Error',
                        icon: ({ theme, type }) => <img src="./gif/sad-cat.gif" className='toast-icon' />,
                    })
                }
            });
    }

    const Unchecked = () => {
        setSelectedOption(null);
    }

    return (
        <div className='Navbar'>
            <input
                type="checkbox"
                name="option"
                id="nav-check"
                value="1"
                checked={selectedOption === "1"}
                onChange={() => handleOptionChange("1")}
                ref={(element) => { refNavBar.current[0] = element }}
            />
            <input
                type="checkbox"
                name="option"
                id="nav-check-right"
                value="2"
                checked={selectedOption === "2"}
                onChange={() => handleOptionChange("2")}
                ref={(element) => { refNavBar.current[1] = element }}
            />

            <div className='Navbar-left' onClick={Unchecked}>
                <Link to='/'>
                    <div className='Navbar-logo'>
                        <img className='img-logo' src='/images/logo.png' alt='logo' />
                        <img className='img-bandeau' src='/images/bandeau.png' alt='logo' />
                    </div>
                </Link>
            </div>
            <div className="Navbar-list">
                <label htmlFor="nav-check">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        className="bi bi-list"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                        />
                    </svg>
                </label>
            </div>
            <div className='Navbar-middle'>
                <Link to='/famille'>
                    <div className='navbar-FA' onClick={Unchecked}>
                        <span className="material-symbols-rounded">
                            home
                        </span>
                        <p>Famille d'Accueil</p>
                    </div>
                </Link>
                <Link to='/adopter'>
                    <div className='navbar-adopter' onClick={Unchecked}>
                        <span className="material-symbols-rounded">
                            pets
                        </span>
                        <p>Adopter</p>
                    </div>
                </Link>
                <Link to='/soutenir'>
                    <div className='navbar-soutenir' onClick={Unchecked}>
                        <span className="material-symbols-rounded">
                            volunteer_activism
                        </span>
                        <p>Soutenir</p>
                    </div>
                </Link>
            </div>
            <div className='Navbar-right'>
                {token ?
                    <div className='navbar-compte'>
                        <label htmlFor="nav-check-right">
                            <span className="material-symbols-rounded">
                                person
                            </span>
                            <p>Mon espace</p>
                        </label>
                    </div>
                    :
                    <Link to='/login'>
                        <div className='navbar-compte' onClick={Unchecked}>
                            <span className="material-symbols-rounded">
                                login
                            </span>
                            <p>Connexion</p>
                        </div>
                    </Link>

                }
            </div>
            <div className='Navbar-right-list'>
                <Link to='/admin' >
                    <div className='navbar-admin' onClick={Unchecked}>
                        <span className="material-symbols-rounded">
                            admin_panel_settings
                        </span>
                        <p>Administration</p>
                    </div>
                </Link>
                <div className='navbar-loggout' onClick={loggedOut}>
                    <span className="material-symbols-rounded">
                        logout
                    </span>
                    <p>Déconnexion</p>
                </div>
            </div>
        </div >
    )
}

export default Navbar