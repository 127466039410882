import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import '../CSS/Contact.css'
import Footer from './Footer'
import GoBackButton from './GoBackButton'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const [message, setMessage] = useState({
        name: null,
        email: null,
        subject: null,
        message: null
    })

    const navigate = useNavigate();

    const handleChange = (e) => {
        setMessage((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const res = await axios.post(process.env.REACT_APP_API_URL + "/send", message)
            if (res.data.status === 201) {
                navigate(-1)
                toast.success(res.data.message, {
                    toastId: 'Success',
                    icon: ({ theme, type }) => <img src="./gif/cat-happy.gif" className='toast-icon' />,
                })
            } else {
                toast.error(res.data.message, {
                    toastId: 'Error',
                    icon: ({ theme, type }) => <img src="./gif/sad-cat.gif" className='toast-icon' />,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
    }, [message])


    return (
        <div className='contact'>
            <GoBackButton location={-1}></GoBackButton>
            <div className="contact-container">
                <div className="contact-box">
                    <div className="contact-left">
                        <div className='contact-gif'>
                            <img src='/gif/cat-message.gif'></img>
                        </div>
                        <div className='contact-coord'>
                            <div className='contact-phone'>
                                <span className="material-symbols-rounded">
                                    call
                                </span>
                                <p>06 52 33 07 57</p>
                            </div>
                            <div className='contact-email'>
                                <span className="material-symbols-rounded">
                                    alternate_email
                                </span>
                                <p>lesbibichouxdestmartin@gmail.com</p>
                            </div>
                            <div className='contact-address'>
                                <span className="material-symbols-rounded">
                                    location_on
                                </span>
                                <p>6 rue Henri Ziegler 31300 Toulouse</p>
                            </div>
                        </div>
                    </div>
                    <div className="contact-right">
                        <form onSubmit={handleSubmit}>
                            <h1>Nous contacter</h1>
                            <input type="text" className="contact-field" name="name" onChange={handleChange} placeholder="Nom complet" />
                            <input type="text" className="contact-field" name="email" onChange={handleChange} placeholder="Email" />
                            <input type="text" className="contact-field" name="subject" onChange={handleChange} placeholder="Sujet" />
                            <textarea placeholder="Message" name="message" onChange={handleChange} className="contact-field"></textarea>
                            <button className="contact-btn">Envoyer</button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Contact