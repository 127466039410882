import React from 'react'
import '../CSS/Home.css'
import Footer from './Footer'
import DonationButton from './DonationButton'
import { Link } from 'react-router-dom'

const Home = () => {
    return (
        <div className='homepage'>
            <div className='homepage-alert'>
                <span className="material-symbols-rounded">
                    warning
                </span>
                <p>En vertue de la loi en vigueur, vous trouverez <Link to='/documents'>ici</Link> le certificat d'engagement et de connaissance</p>
                <span className="material-symbols-rounded">
                    warning
                </span>
            </div>
            <div className='homepage-Asso'>
                <div className="custom-shape-divider-top-1670426874">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
                <div className='homepage-Asso-title'>
                    <h2>Les Bibichoux de St. Martin</h2>
                </div>
                <div className='homepage-Asso-info'>

                    <div>
                        <p>
                            Nunc vero inanes flatus quorundam vile esse quicquid extra urbis pomerium nascitur aestimant praeter orbos et caelibes, nec credi potest qua obsequiorum diversitate coluntur homines sine liberis Romae.
                        </p>
                        <p>
                            Oportunum est, ut arbitror, explanare nunc causam, quae ad exitium praecipitem Aginatium inpulit iam inde a priscis maioribus nobilem, ut locuta est pertinacior fama. nec enim super hoc ulla documentorum rata est fides.
                        </p>
                    </div>
                    <div className='but-presentation'>
                        <Link to="/asso">
                            <button><p>Présentation de l'association</p></button>
                        </Link>
                    </div>

                </div>

            </div>
            <div className='homepage-FA-container'>
                <div className="custom-shape-divider-bottom-1670422563">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
                <div className='homepage-FA'>
                    <div className='homepage-FA-img'>
                        <img src='/images/img-FA.jpg' alt='img-FA'></img>
                    </div>
                    <div className='homepage-FA-info'>

                        <h2>Devenir famille d'accueil</h2>
                        <div>
                            <p>
                                Nunc vero inanes flatus quorundam vile esse quicquid extra urbis pomerium nascitur aestimant praeter orbos et caelibes, nec credi potest qua obsequiorum diversitate coluntur homines sine liberis Romae.
                            </p>
                            <p>
                                Oportunum est, ut arbitror, explanare nunc causam, quae ad exitium praecipitem Aginatium inpulit iam inde a priscis maioribus nobilem, ut locuta est pertinacior fama. nec enim super hoc ulla documentorum rata est fides.
                            </p>
                        </div>
                        <Link to="/famille">
                            <button><p>Devenir Famille d'Accueil</p></button>
                        </Link>
                    </div>
                </div>
                <div className="custom-shape-divider-top-1670426874">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
            </div>
            <div className='homepage-adopter'>
                <div className='homepage-adopter-img-mobile'>
                    <img src='/images/img-adopter.jpg' alt='img-adopter'></img>
                </div>
                <div className='homepage-adopter-info'>
                    <h2>Adopter un chat</h2>
                    <div>
                        <p>
                            Nunc vero inanes flatus quorundam vile esse quicquid extra urbis pomerium nascitur aestimant praeter orbos et caelibes, nec credi potest qua obsequiorum diversitate coluntur homines sine liberis Romae.
                        </p>
                        <p>
                            Oportunum est, ut arbitror, explanare nunc causam, quae ad exitium praecipitem Aginatium inpulit iam inde a priscis maioribus nobilem, ut locuta est pertinacior fama. nec enim super hoc ulla documentorum rata est fides.
                        </p>
                    </div>
                    <Link to="/adopter">
                        <button><p>Adopter un chat</p></button>
                    </Link>
                </div>
                <div className='homepage-adopter-img'>
                    <img src='/images/img-adopter.jpg' alt='img-adopter'></img>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Home