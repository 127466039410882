import React from 'react'
import Footer from '../Components/Footer'
import '../CSS/FamilleAccueil.css'

const FamilleAccueil = () => {
    return (
        <div className='famille'>
            <div className='famille-container'>
                <div className='famille-title'>
                    <h1>Famille d'accueil</h1>
                </div>
                <div className='famille-text'>
                    <h2>Vivre une expérience incroyable</h2>
                    <p>Devenir une famille d'accueil pour un animal dans le besoin peut être <strong>l'une des expériences les plus enrichissantes et gratifiantes que vous puissiez vivre.</strong> En offrant un refuge temporaire à un animal, vous lui donnez <strong>une seconde chance dans la vie.</strong> Vous pouvez également aider à fournir aux animaux en famille d'accueil <strong>l'attention, les soins individuels et la tendresse dont ils ont besoin </strong>pour se rétablir de traumatismes émotionnels ou physiques. De plus, en tant que famille d'accueil, vous pourrez en apprendre davantage sur les animaux et leurs besoins uniques, ce qui peut vous aider à mieux comprendre les comportements et les besoins de vos propres animaux de compagnie.</p>
                    <h2>Sauver des vies</h2>
                    <p>Mais il y a plus que cela. En devenant une famille d'accueil, vous faites également <strong>une contribution importante à la communauté.</strong> Vous aidez à réduire le nombre d'animaux abandonnés et sans abri, tout en offrant aux organismes de protection animale le temps et les ressources nécessaires pour trouver des foyers permanents pour ces animaux. En fin de compte, <strong>vous pouvez aider à sauver des vies en devenant une famille d'accueil pour un animal dans le besoin.</strong></p>
                    <h2>Projection d'adoption</h2>
                    <p>Si vous avez déjà envisagé d'adopter un animal, mais que vous n'êtes pas tout à fait prêt pour un engagement à long terme, devenir une famille d'accueil peut être <strong>un excellent moyen de découvrir si vous êtes prêt pour une adoption permanente.</strong> Vous pouvez commencer par accueillir un animal pendant une période déterminée et décider par la suite si vous êtes prêt pour un engagement plus long.</p>
                    <br></br>
                    <p>En bref, <strong>devenir une famille d'accueil pour un animal dans le besoin est une décision importante, mais gratifiante.</strong> Vous pouvez aider à sauver des vies et faire une contribution significative à la communauté. Si vous êtes prêt à offrir votre temps, votre amour et votre foyer à un animal dans le besoin, <strong>n'hésitez pas à nous contacter pour en savoir plus sur la façon dont vous pouvez devenir une famille d'accueil.</strong></p>
                </div>
                <div className='famille-bottom'>
                    <div className='famille-livret'>
                        <div className='famille-livret-title'>
                            <p>Vous pouvez consulter notre livret famille d'accueil pour avoir plus d'informations.</p>
                        </div>
                        <div className='famille-livret-button'>
                            <a href={process.env.PUBLIC_URL + '/pdf/Livret FA 2022.pdf'} download>
                                <button>
                                    <span className="material-symbols-rounded">
                                        download
                                    </span>
                                    <p>Télécharger le livret famille d'accueil</p>
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className='famille-form'>
                        <div className='famille-form-title'>
                            <p>Pour devenir famille d'accueil au sein de notre association, il vous suffit de remplir le formulaire ci-dessous et nous vous contacterons.</p>
                        </div>
                        <div className='famille-info-formulaire'>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSf7LcqInDB8jLMD8AD9Cn9px-j9ThO4Xvmk74_U-Hv49g8qBA/viewform?usp=pp_url" target="_blank">
                                <button>
                                    <span className="material-symbols-rounded">
                                        assignment
                                    </span>
                                    <p>Lien vers le formulaire famille d'accueil</p>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default FamilleAccueil