import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import '../CSS/Chat.css'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from 'react-toastify'

const Chat = (props) => {

  const [imgName, setImgName] = useState(null)

  var age = getAge(props.info.age)

  const navigate = useNavigate()

  axios.interceptors.request.use(
    config => {
      config.headers.authorization = `Bearer ${props.token}`
      return config
    }
  )

  const getImage = async (id) => {
    const res3 = await axios.get(process.env.REACT_APP_API_URL + `/images/${props.info.id}`, {
      headers: {
        "Content-Type": "application/json"
      }
    })

    if (res3.status === 200 && res3.data && res3.data.length > 0) {
      setImgName(res3.data[0].image)
    }
  }

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirmation',
      message: `Etes-vous sur de vouloir supprimer ${props.info.name} ?`,
      buttons: [
        {
          label: 'Oui',
          onClick: () => deleteChat(id)
        },
        {
          label: 'Annuler'
        }
      ]
    });
  }

  const deleteChat = async (id) => {
    const res = await axios.delete(process.env.REACT_APP_API_URL + `/chats/${id}`, {
      headers: {
        "Content-Type": "application/json"
      }
    })

    if (res.status === 201) {
      props.stateChanger(true)
      toast.success(res.data.message, {
        toastId: 'Success',
        icon: ({ theme, type }) => <img src="./gif/cat-happy.gif" className='toast-icon' />,
      })
    } else {
      toast.error(res.data.message, {
        toastId: 'Error',
        icon: ({ theme, type }) => <img src="./gif/sad-cat.gif" className='toast-icon' />,
      })
    }
  }

  function updateChat(id) {
    props.idChat(id)
    navigate(`/update/${id}`)
  }

  function getAge(dtn) {

    const today = new Date();
    const dateNaissance = new Date(dtn);

    const nbJour = Math.floor((today - dateNaissance) / 1000 / 3600 / 24);

    let mois = Math.floor(nbJour / 30)
    let an = 0;

    if (mois > 12) {
      an = Math.floor(mois / 12)
      mois = mois % 12
    }

    if (an === 0) {
      age = "" + mois + " mois"
    } else {
      age = "" + an + " an(s) et " + mois + " mois"
    }

    return age
  }

  function afficherChat(idC) {
    props.idChat(idC);
    navigate(`/adopter/${idC}`)
  }

  useEffect(() => {
    getImage(props.info.id)
  })

  const ImageBack = React.memo(function Image() {
    return <img src={`/uploads/${imgName}`} className="img-back" alt="img"/>;
  });

  const ImageFront = React.memo(function Image() {
    return <img src={`/uploads/${imgName}`} className="img-front" alt="img"/>;
  });



  return (
    <div className='chat'>
      <div className='chat-img' onClick={() => afficherChat(props.info.id)}>
        {imgName && <ImageBack></ImageBack>}
        {imgName && <ImageFront></ImageFront>}
      </div>
      <div className='chat-description'>
        <div className='description-top'>
          <h2>{props.info.name}</h2>
          {(props.info.sexe === 'M') ?
            <div className='description-sexe'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill='#2072ff' d="M289.8 46.8c3.7-9 12.5-14.8 22.2-14.8H424c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-33.4-33.4L321 204.2c19.5 28.4 31 62.7 31 99.8c0 97.2-78.8 176-176 176S0 401.2 0 304s78.8-176 176-176c37 0 71.4 11.4 99.8 31l52.6-52.6L295 73c-6.9-6.9-8.9-17.2-5.2-26.2zM400 80l0 0h0v0zM176 416a112 112 0 1 0 0-224 112 112 0 1 0 0 224z" /></svg>
              <p>Mâle</p>
            </div>
            :
            <div className='description-sexe'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill='#ff30c4' d="M64 176a112 112 0 1 1 224 0A112 112 0 1 1 64 176zM208 349.1c81.9-15 144-86.8 144-173.1C352 78.8 273.2 0 176 0S0 78.8 0 176c0 86.3 62.1 158.1 144 173.1V384H112c-17.7 0-32 14.3-32 32s14.3 32 32 32h32v32c0 17.7 14.3 32 32 32s32-14.3 32-32V448h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H208V349.1z" /></svg>
              <p>Femelle</p>
            </div>
          }
        </div>
        <div className='description-botom'>
          <p>{props.info.pelage}</p>
          <p>{age}</p>
        </div>
      </div>
      {props.isAdmin &&
        <div className='admin-button'>
          <button className='button-update' onClick={() => updateChat(props.info.id)}>
            <span className="material-symbols-rounded">
              update
            </span>
            <p>Modifier</p>
          </button>
          <button className='button-delete' onClick={() => handleDelete(props.info.id)}>
            <span className="material-symbols-rounded">
              delete
            </span>
            <p>Supprimer</p>
          </button>
        </div>
      }
    </div>
  )
}

export default Chat