import React from 'react'
import '../CSS/BoutiqueForm.css'
import Footer from './Footer'
import GoBackButton from './GoBackButton'

const BoutiqueForm = () => {
    return (
        <div className='boutique'>
            <GoBackButton location='/soutenir'></GoBackButton>

            <div className='boutique-info'>
                <h1>Boutique</h1>
                <p>Bienvenue dans la boutique en ligne l'association Les Bibichoux de St Martin ! Nous sommes fiers de vous proposer des figurines et des porte-clés exclusifs fabriqués par nos soins. Vous pouvez contribuer à soutenir nos actions pour protéger et améliorer la vie des chats dans le besoin tout en faisant plaisir vos proches ou à bien vous-même !</p>
                <p><strong>Attention : Nous ne vendons pas nos produits, nous les donnons en échange d'un don à l'association.</strong></p>
                <p><strong>Pensez à cocher la case “Je ne souhaite pas soutenir HelloAsso”</strong> lors du paiement pour ne pas payer des frais supplémentaires.</p>
                <div className='boutique-image'>
                    <img src='/images/porte-cle.jpg'></img>
                    <img src='/images/figurine.jpg'></img>
                </div>
            </div>
            <div className='boutique-form'>
                <iframe id="haWidget" allowtransparency="true" scrolling="auto" src="https://www.helloasso.com/associations/les-bibichoux-de-st-martin/boutiques/test/widget" style={{ width: '100%', height: '100dvh', border: 'none' }}></iframe>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default BoutiqueForm