import React from 'react'
import '../CSS/DonationForm.css'
import Footer from './Footer'
import GoBackButton from './GoBackButton'

const DonationForm = () => {
  return (
    <div className='donation'>
      <GoBackButton location='/soutenir'></GoBackButton>
      <div className='donation-info'>
        <h1>Faire un don</h1>
        <p>Les animaux abandonnés sont confrontés à la peur, le froid, la faim, la maladie ou encore la maltraitance. Dans la protection animale, nous apportons à nos protégés soins vétérinaires nécessaires et nourriture. Dans la protection animale, on ne compte ni nos heures, ni notre énergie, ni nos kilomètres, une seule chose en tête « tout faire pour sauver nos 4 pattes ». Mais malheureusement le coût financier, lui, on ne peut pas l’oublier, sans lui on ne peut rien faire. C'est pour cela que nous comptons sur votre générosité.</p>
        <p>Tout don effectué engendre une <strong>réduction d'impot de 66%.</strong> </p>
        <p>Par exemple : <strong>pour un don de 200 €</strong> à l'association Les Bibichoux de St Martin, <strong>vous avez droit à une réduction d'impôt de : 132 €</strong> (200 € x 66 %). <strong>Il ne vous revient alors qu'à 64 €.</strong></p>
      </div>
      <div className='donation-form'>
        <iframe id="haWidget" allowtransparency="true" scrolling="auto" src="https://www.helloasso.com/associations/les-bibichoux-de-st-martin/formulaires/1/widget" style={{ width: '100%', height: '100dvh', border: 'none', position: 'relative' }}></iframe>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default DonationForm