import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Loader from './Loader'
import ChatPlaceholder from './ChatPlaceholder';
import '../CSS/ChatsAdopte.css'
import ChatAdopte from './ChatAdopte';
import Footer from './Footer'
import GoBackButton from './GoBackButton';

const ChatsAdopte = () => {
    const [chatsAdopte, setChatsAdopte] = useState(null)
    const [query, setQuery] = useState("")
    const [isLoaded, setLoaded] = useState(false);

    const getChatsAdopte = async () => {
        const res = await axios.get(process.env.REACT_APP_API_URL + `/chatsAdopte`, {
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (res.status === 200) {
            setChatsAdopte(res.data)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
        }, 2000);

        getChatsAdopte()
    }, [])

    return (
        <div className='chats-adopte'>
            <GoBackButton location='/asso'></GoBackButton>
            <div className='chats-main-adopte'>
                <div className='chats-title'>
                    <div className='title-left'></div>
                    <div className='title-middle'>
                        <h1>Mur d'adoptions</h1>
                    </div>
                    <div className='title-right'>
                        <input type="text" placeholder='Recherche...' className='Recherche' onChange={e => setQuery(e.target.value)}></input>
                    </div>
                </div>
                <div className='chats-list-page'>
                    {isLoaded &&
                        <div className='chats-list-title'>
                            <span className="material-symbols-rounded">
                                pets
                            </span>
                            <h3>{chatsAdopte.length} loulous ont trouvé une famille</h3>
                            <span className="material-symbols-rounded">
                                pets
                            </span>
                        </div>
                    }
                    {!isLoaded
                        ? <div className='page-not-loaded'>
                            <Loader />
                            <div className='chats-list-notloaded'>
                                {chatsAdopte && chatsAdopte.map((chat, index) => (
                                    <ChatPlaceholder key={index}></ChatPlaceholder>
                                ))}
                            </div>
                        </div>
                        :
                        <div className='chats-list'>
                            {chatsAdopte && chatsAdopte.filter((chat) =>
                                chat.nom.toLowerCase().includes(query)
                            ).length === 0 ?
                                <div className='chats-list-notfound'>
                                    <img src='/gif/peach-cat-cute.gif' alt='gif-sorry'></img>
                                    <p>Désolé, nous n'avons fait adopter aucun chat à ce nom.</p>
                                </div>
                                : chatsAdopte.filter((chat) => chat.nom.toLowerCase().includes(query)).map((chat, index) => (
                                    <ChatAdopte
                                        key={index}
                                        info={{ id: chat.ID, name: chat.nom }}
                                    ></ChatAdopte>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
            <Footer></Footer>
        </div >
    )
}

export default ChatsAdopte