import React, { useRef, useEffect, useState } from 'react'
import axios from 'axios'
import '../CSS/AdminChats.css'
import Chat from './Chat';
import Loader from './Loader';
import ChatPlacehorlder from './ChatPlaceholder';
import { Link } from 'react-router-dom';
import Footer from './Footer';

const AdminChats = (props) => {

    const [allChats, setAllChats] = useState([])
    const [isLoaded, setLoaded] = useState(false);
    const [query, setQuery] = useState("")
    const [isAdmin, setAdmin] = useState(true)
    const [token, setToken] = useState(false)
    const [dataUpdate, setDataUpdate] = useState(false)
    const [chatsDisplayed, setChatsDisplayed] = useState([])
    const [activeFilter, setActiveFilter] = useState([])
    const [chatsFiltered, setChatsFiltered] = useState([])

    const ref = useRef([]);

    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

    const getAdminChats = async () => {
        if (activeFilter.length === 0) {
            const res = await axios.get(process.env.REACT_APP_API_URL + `/chatsAdmin`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                setChatsDisplayed(res.data)
                setAllChats(res.data)
            } else {
                console.log("error");
            }
            setChatsFiltered([])
        } else {
            const res = await axios.get(process.env.REACT_APP_API_URL + `/chatsAdmin?_filter=${activeFilter}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (res.status === 200) {
                setChatsDisplayed(res.data)
                setChatsFiltered(res.data)
            }
        }
    }

    const Unchecked = () => {
        if (activeFilter.length !== 0) {
            setActiveFilter([])
            setChatsFiltered([])

            for (let i = 0; i < ref.current.length; i++) {
                ref.current[i].checked = false;
            }
        }
    }

    const handleFilter = async (e) => {
        if (e.target.checked) {
            setActiveFilter([...activeFilter, e.target.value])
        } else {
            setActiveFilter((filters) => filters.filter((_, index) => index !== activeFilter.indexOf(e.target.value)))
        }
    }

    useEffect(() => {
        setToken(props.token)
        setTimeout(() => {
            setLoaded(true)
        }, 2000);
    }, [isLoaded]);

    useEffect(() => {
        //console.log(chatsDisplayed);
    }, [chatsDisplayed, allChats, query])

    useEffect(() => {
        //console.log("Filtres actifs : " + activeFilter);
        getAdminChats()
    }, [activeFilter,dataUpdate])

    useEffect(() => {
        setDataUpdate(false)
    }, [dataUpdate])


    return (
        <div className='chats'>
            <div className='chats-title'>
                <div className='title-left-admin'>
                    <Link to='/add'>
                        <button>
                            <span className="material-symbols-rounded">
                                add
                            </span>
                            <p>Ajouter</p>
                        </button>
                    </Link>
                </div>
                <div className='title-middle'>
                    <h1>Administration</h1>
                </div>
                <div className='title-right'>
                    <input type="text" placeholder='Recherche...' className='Recherche' onChange={e => setQuery(e.target.value)}></input>
                </div>
            </div>
            <div className='chats-main chats-main-admin'>
                <input type="checkbox" id="filter-check" />
                <label htmlFor="filter-check">
                    <div className='chats-filters-mobile'>
                        <span className="material-symbols-rounded">
                            filter_list
                        </span>
                        <p>Filtres</p>
                    </div>
                </label>
                <div className='chats-filters chats-filters-admin'>
                    <label htmlFor="filter-check">
                        <div className='close-filters'>
                            <div className="leftright"></div>
                            <div className="rightleft"></div>
                        </div>
                    </label>
                    <div className='filters-title'>
                        <span className="material-symbols-rounded">
                            filter_list
                        </span>
                        <p>Filtres</p>
                    </div>
                    <div className='active-filters'>
                        <p>{activeFilter.length} filtre(s) actif(s) :</p>
                        {chatsFiltered.length !== 0 && <p>{chatsFiltered.length} chat(s) trouvé(s)</p>}
                    </div>
                    <div className='filters-list'>
                        <label htmlFor="filter-check-couleur">
                            <input type="checkbox" id="filter-check-couleur" />
                            <div className='filter-category-couleur'>
                                <p>Couleur</p>
                                <span className="material-symbols-rounded">
                                    expand_more
                                </span>
                            </div>
                            <div className='submenu-couleur'>
                                <div className='filter-blanc'>
                                    <label htmlFor="filter-check-blanc">
                                        <p>Blanc</p>
                                        <input type="checkbox" id="filter-check-blanc" ref={(element) => { ref.current[0] = element }} onChange={handleFilter} value="blanc" />
                                    </label>
                                </div>
                                <div className='filter-noir'>
                                    <label htmlFor="filter-check-noir">
                                        <p>Noir</p>
                                        <input type="checkbox" id="filter-check-noir" ref={(element) => { ref.current[1] = element }} onChange={handleFilter} value="noir" />
                                    </label>
                                </div>
                                <div className='filter-roux'>
                                    <label htmlFor="filter-check-roux"><p>Roux</p>
                                        <input type="checkbox" id="filter-check-roux" ref={(element) => { ref.current[2] = element }} onChange={handleFilter} value="roux" />
                                    </label>
                                </div>
                                <div className='filter-gris'>
                                    <label htmlFor="filter-check-gris"><p>Gris</p>
                                        <input type="checkbox" id="filter-check-gris" ref={(element) => { ref.current[3] = element }} onChange={handleFilter} value="gris" />
                                    </label>
                                </div>
                                <div className='filter-marron'>
                                    <label htmlFor="filter-check-marron"><p>Marron</p>
                                        <input type="checkbox" id="filter-check-marron" ref={(element) => { ref.current[4] = element }} onChange={handleFilter} value="marron" />
                                    </label>
                                </div>
                            </div>
                        </label>
                        <label htmlFor="filter-check-robe">
                            <input type="checkbox" id="filter-check-robe" />
                            <div className='filter-category-robe'>
                                <p>Robe</p>
                                <span className="material-symbols-rounded">
                                    expand_more
                                </span>
                            </div>
                            <div className='submenu-robe'>
                                <div className='filter-unie'>
                                    <label htmlFor="filter-check-unie">
                                        <p>Unie</p>
                                        <input type="checkbox" id="filter-check-unie" ref={(element) => { ref.current[5] = element }} onChange={handleFilter} value="unie" />
                                    </label>
                                </div>
                                <div className='filter-tigree'>
                                    <label htmlFor="filter-check-tigree"><p>Tigrée</p>
                                        <input type="checkbox" id="filter-check-tigree" ref={(element) => { ref.current[6] = element }} onChange={handleFilter} value="tigree" />
                                    </label>
                                </div>
                                <div className='filter-bicolore'>
                                    <label htmlFor="filter-check-bicolore"><p>Bicolore</p>
                                        <input type="checkbox" id="filter-check-bicolore" ref={(element) => { ref.current[7] = element }} onChange={handleFilter} value="bicolore" />
                                    </label>
                                </div>
                                <div className='filter-tricolore'>
                                    <label htmlFor="filter-check-tricolore"><p>Tricolore</p>
                                        <input type="checkbox" id="filter-check-tricolore" ref={(element) => { ref.current[8] = element }} onChange={handleFilter} value="tricolore" />
                                    </label>
                                </div>
                                <div className='filter-colorpoint'>
                                    <label htmlFor="filter-check-colorpoint"><p>Colorpoint</p>
                                        <input type="checkbox" id="filter-check-colorpoint" ref={(element) => { ref.current[9] = element }} onChange={handleFilter} value="colorpoint" />
                                    </label>
                                </div>
                            </div>
                        </label>
                        <label htmlFor="filter-check-sexe">
                            <input type="checkbox" id="filter-check-sexe" />
                            <div className='filter-category-sexe'>
                                <p>Sexe</p>
                                <span className="material-symbols-rounded">
                                    expand_more
                                </span>
                            </div>
                            <div className='submenu-sexe'>
                                <div className='filter-male'>
                                    <label htmlFor="filter-check-male"><p>Mâle</p>
                                        <input type="checkbox" id="filter-check-male" ref={(element) => { ref.current[10] = element }} onChange={handleFilter} value="M" /></label>
                                </div>
                                <div className='filter-femelle'>
                                    <label htmlFor="filter-check-femelle"><p>Femelle</p>
                                        <input type="checkbox" id="filter-check-femelle" ref={(element) => { ref.current[11] = element }} onChange={handleFilter} value="F" />
                                    </label>
                                </div>
                            </div>
                        </label>
                        <label htmlFor="filter-check-age">
                            <input type="checkbox" id="filter-check-age" />
                            <div className='filter-category-age'>
                                <p>Age</p>
                                <span className="material-symbols-rounded">
                                    expand_more
                                </span>
                            </div>
                            <div className='submenu-age'>
                                <div className='filter-junior'>
                                    <label htmlFor="filter-check-junior"><p>Junior</p>
                                        <input type="checkbox" id="filter-check-junior" ref={(element) => { ref.current[12] = element }} onChange={handleFilter} value="junior" />
                                    </label>
                                </div>
                                <div className='filter-adulte'>
                                    <label htmlFor="filter-check-adulte"><p>Adulte</p>
                                        <input type="checkbox" id="filter-check-adulte" ref={(element) => { ref.current[13] = element }} onChange={handleFilter} value="adulte" />
                                    </label>
                                </div>
                                <div className='filter-senior'>
                                    <label htmlFor="filter-check-senior"><p>Senior</p>
                                        <input type="checkbox" id="filter-check-senior" ref={(element) => { ref.current[14] = element }} onChange={handleFilter} value="senior" />
                                    </label>
                                </div>
                            </div>
                        </label>
                        <label htmlFor="filter-check-visibility">
                            <input type="checkbox" id="filter-check-visibility" />
                            <div className='filter-category-visibility'>
                                <p>Visibilité</p>
                                <span className="material-symbols-rounded">
                                    expand_more
                                </span>
                            </div>
                            <div className='submenu-visibility'>
                                <div className='filter-adoption'>
                                    <label htmlFor="filter-check-adoption"><p>A l'adoption</p>
                                        <input type="checkbox" id="filter-check-adoption" ref={(element) => { ref.current[15] = element }} onChange={handleFilter} value="adoption" />
                                    </label>
                                </div>
                                <div className='filter-adopte'>
                                    <label htmlFor="filter-check-adopte"><p>Adopté</p>
                                        <input type="checkbox" id="filter-check-adopte" ref={(element) => { ref.current[16] = element }} onChange={handleFilter} value="adopte" />
                                    </label>
                                </div>
                                <div className='filter-masque'>
                                    <label htmlFor="filter-check-masque"><p>Masqué</p>
                                        <input type="checkbox" id="filter-check-masque" ref={(element) => { ref.current[17] = element }} onChange={handleFilter} value="masque" />
                                    </label>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className='filter-reset'>
                        <button onClick={Unchecked}>
                            <div className='filter-reset-btn'>
                                <span className="material-symbols-rounded">
                                    filter_list_off
                                </span>
                                <p>Réinitialiser</p>
                            </div>
                        </button>
                    </div>
                </div>
                <input type="checkbox" id="filter-check" />
                <div className='chats-list-page-title-mobile'>
                    {isLoaded &&
                        <div className='chats-list-title'>
                            <span className="material-symbols-rounded">
                                pets
                            </span>
                            <h3>Nous avons {allChats.length} loulous enregistrés</h3>
                            <span className="material-symbols-rounded">
                                pets
                            </span>
                        </div>
                    }
                </div>

                <div className='chats-list-page chats-list-page-admin'>
                    <div className='chats-list-page-title'>
                        {isLoaded &&
                            <div className='chats-list-title'>
                                <span className="material-symbols-rounded">
                                    pets
                                </span>
                                <h3>Nous avons {allChats.length} loulous enregistrés</h3>
                                <span className="material-symbols-rounded">
                                    pets
                                </span>
                            </div>
                        }
                    </div>
                    {!isLoaded
                        ? <div>
                            <Loader />
                            <div className='chats-list-notloaded'>
                                {chatsDisplayed && chatsDisplayed.map((chat, index) => (
                                    <ChatPlacehorlder key={index}></ChatPlacehorlder>
                                ))}
                            </div>
                        </div>
                        : (chatsDisplayed.length === 0 && activeFilter.length !== 0)
                            ? <div className='chats-list-notfound'>
                                <img src='/gif/peach-cat-cute.gif' alt='gif-sorry'></img>
                                <p>Désolé, nous n'avons trouvé aucun chat correspondant à vos critères.</p>
                            </div>
                            :
                            <div className='chats-list'>
                                {chatsDisplayed && chatsDisplayed.filter((chat) =>
                                    chat.nom.toLowerCase().includes(query)
                                ).length === 0 ?
                                    <div className='chats-list-notfound'>
                                        <img src='/gif/peach-cat-cute.gif' alt='gif-sorry'></img>
                                        <p>Désolé, nous n'avons trouvé aucun chat correspondant à votre recherche.</p>
                                    </div>
                                    : chatsDisplayed.filter((chat) => chat.nom.toLowerCase().includes(query)).map((chat, index) => (
                                        <Chat
                                            key={index}
                                            info={{ id: chat.ID, name: chat.nom, age: chat.date_naiss, pelage: chat.pelage, sexe: chat.sexe }}
                                            isAdmin={isAdmin}
                                            token={token}
                                            stateChanger={setDataUpdate}
                                            idChat={props.idChat}
                                        ></Chat>
                                    ))
                                }
                            </div>
                    }
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default AdminChats