import React from 'react'
import Footer from './Footer'
import "../CSS/DonationPage.css"
import DownArrow from './DownArrow'
import { Link } from 'react-router-dom'

const DonationPage = () => {
    const id = "Explanation"

    return (
        <div className='donationPage'>
            <div className='imgBackground'>
                <div className='main-button'>
                    <Link to='/boutique'>
                    <div className='button-shop'>
                        <span className="material-symbols-rounded">
                            shopping_basket
                        </span>
                        <p>Boutique</p>
                    </div>
                    </Link>
                    <Link to="/adherer">
                        <div className='button-member'>
                            <span className="material-symbols-rounded">
                                groups
                            </span>
                            <p>Adhésion</p>
                        </div>
                    </Link>
                    <Link to="/donner">
                        <div className='button-donnation'>
                            <span className="material-symbols-rounded">
                                volunteer_activism
                            </span>
                            <p>Donation</p>
                        </div>
                    </Link>
                </div>
                <DownArrow id={"#" + id}></DownArrow>
            </div>
            <div id={id} className='explanation'>
                <h1>Pourquoi nous soutenir ?</h1>
                <p>Les animaux abandonnés sont confrontés à la peur, le froid, la faim, la maladie ou encore la maltraitance. Dans la protection animale, nous apportons à nos protégés soins vétérinaires nécessaires et nourriture. Dans la protection animale, on ne compte ni nos heures, ni notre énergie, ni nos kilomètres, une seule chose en tête « tout faire pour sauver nos 4 pattes ». Mais malheureusement le coût financier, lui, on ne peut pas l’oublier, sans lui on ne peut rien faire. C'est pour cela que nous comptons sur votre générosité.</p>
                <p>Tout don effectué engendre une <strong>réduction d'impot de 66%.</strong> </p>
                <p>Par exemple : <strong>pour un don de 200 €</strong> à l'association Les Bibichoux de St Martin, <strong>vous avez droit à une réduction d'impôt de : 132 €</strong> (200 € x 66 %). <strong>Il ne vous revient alors qu'à 64 €.</strong></p>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default DonationPage