import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

function ScrollToTop() {

    const {pathname} = useLocation();
    useEffect(() => {
        document.body.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [pathname]);
}

export default ScrollToTop;