import React from 'react'
import '../CSS/AdherentForm.css'
import Footer from './Footer'
import GoBackButton from './GoBackButton'

const AdherentForm = () => {
    return (
        <div className='adherent'>
            <GoBackButton location='/soutenir'></GoBackButton>
            <div className='adherent-info'>
                <h1>Devenir adhérent</h1>
                <p>En devenant adhérent, vous aurez la possibilité de <strong>participer à la vie de notre association </strong>! Vous recevrez aussi une <strong>newsletter mensuelle </strong>qui récapitule nos diverses actions et vous aurez la possibilité d’<strong>assister et de faire entendre votre voix lors de notre Assemblée Générale.</strong></p>
                <p>En devenant adhérent, vous <strong>soutenez financièrement notre association</strong> dans son combat quotidien et vous nous permettez aussi d’<strong>avoir plus de poids lors de nos démarches</strong> auprès des pouvoirs publics ou des grandes fondations puisque le nombre d’adhérents peut être un facteur essentiel lors de la rédaction de certains dossiers.</p>
                <br />
                <h2>Les différentes adhésions :</h2>
                <ul>
                    <li><p>- Adhésion simple : 20€</p></li>
                    <li><p>- Adhésion libre : montant supérieur à 20€</p></li>
                </ul>
                <p>Votre adhésion sera <strong>valable pour une année </strong>à compter de la réception de votre paiement.</p>
                <br />
                <h2>Comment devenir adhérent :</h2>
                <p>Tout simplement en remplissant <strong>le bulletin d’adhésion</strong> et nous le retourner par email à l'adresse :
                    <strong> lesbibichouxdestmartin@gmail.com</strong></p>
            </div>
            <div className='ressource-adherent'>
                <a href={process.env.PUBLIC_URL + '/pdf/Bulletin-Adhesion.pdf'} download>
                    <button>
                        <div className='buletin-adhesion'>
                            <span className="material-symbols-rounded">
                                download
                            </span>
                            <p>Télécharger le bulletin d'adhésion</p>
                        </div>
                    </button>
                </a>
                <p>Les règlements peuvent aussi se faire <strong>par virement</strong> sur demande ou <strong>par chèque </strong>à l'adresse suivante et bien sûr directement <strong>via le formulaire HelloAsso</strong> ci-dessous : </p>
                <p>6 rue Henri ZIEGLER 31300 Toulouse</p>
            </div>
            <div className='form-adherent'>
                <iframe id="haWidget" allowtransparency="true" scrolling="auto" src="https://www.helloasso.com/associations/les-bibichoux-de-st-martin/adhesions/devenir-adherent/widget" style={{ width: '100%', height: '100dvh', border: 'none' }}></iframe>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default AdherentForm