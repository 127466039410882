import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import "../CSS/ChatInfo.css"
import GoBackButton from '../Components/GoBackButton'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Footer from './Footer'
import { useSelector } from 'react-redux'
import Loader from './Loader'
import ChatInfoPlaceholder from './ChatInfoPlaceholder'

const ChatInfo = () => {

  const [chat, setChat] = useState(null)
  const [imgList, setImgList] = useState(null)
  const [error, setError] = useState(false)
  const [nom, setNom] = useState(null)

  const token = useSelector(state => state.auth.token);

  const navigate = useNavigate()

  const { idChat } = useParams()

  const getChat = async (id) => {
    if (!token) {
      const res = await axios.get(process.env.REACT_APP_API_URL + `/chats/${id}`, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (res.data.status === 200) {
        setChat(res.data.data);
      } else {
        setNom(res.data.nom)
        setError(true)
      }
    } else {
      const res = await axios.get(process.env.REACT_APP_API_URL + `/chatsAdmin/${id}`, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (res.data.status === 200) {
        setChat(res.data.data);
      } else {
        setError(true)
      }
    }
  }

  function getAge(dtn) {

    const today = new Date();
    const dateNaissance = new Date(dtn);

    const nbJour = Math.floor((today - dateNaissance) / 1000 / 3600 / 24);

    let mois = Math.floor(nbJour / 30)
    let an = 0;

    if (mois > 12) {
      an = Math.floor(mois / 12)
      mois = mois % 12
    }

    let age;

    if (an === 0) {
      age = "" + mois + " mois"
    } else {
      age = "" + an + " an(s) et " + mois + " mois"
    }

    return age
  }

  const getImage = async () => {
    const res3 = await axios.get(process.env.REACT_APP_API_URL + `/images/${idChat}`, {
      headers: {
        "Content-Type": "application/json"
      }
    })

    if (res3.status === 200) {
      setImgList(res3.data)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getChat(idChat)
      getImage(idChat)
    }, 1000);

  }, [])

  return (
    <>
      <div className='chat-page'>
        <GoBackButton location={-1}></GoBackButton>
        {
          error ? (
            <div className='error-chat page-not-found'>
              <img src='/gif/peach-cat-cute.gif' alt='gif-sorry'></img>
              {
                nom ? (
                  <p>Désolé, le chat {nom} n'est plus à l'adoption.</p>
                )
                :
                (
                  <p>Désolé, ce chat n'existe pas.</p>
                )
              }
              
            </div>
          )
            : chat ? (
              <div className='fiche-chat'>
                <div className='container-info'>
                  <div className='img-chat'>
                    <Carousel infiniteLoop useKeyboardArrows>
                      {imgList && imgList.map((fadeImage, index) => (
                        <div key={index}>
                          <img src={`/uploads/${fadeImage.image}`} />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <div className='info-chat'>
                    <div className='info-principales'>
                      <h1>Caractéristiques</h1>
                      <div className='info-top'>
                        <h2>{chat[0].nom}</h2>
                        {(chat[0].sexe === 'M') ?
                          <div className='description-sexe'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill='#2072ff' d="M289.8 46.8c3.7-9 12.5-14.8 22.2-14.8H424c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-33.4-33.4L321 204.2c19.5 28.4 31 62.7 31 99.8c0 97.2-78.8 176-176 176S0 401.2 0 304s78.8-176 176-176c37 0 71.4 11.4 99.8 31l52.6-52.6L295 73c-6.9-6.9-8.9-17.2-5.2-26.2zM400 80l0 0h0v0zM176 416a112 112 0 1 0 0-224 112 112 0 1 0 0 224z" /></svg>
                            <p>Mâle</p>
                          </div>
                          :
                          <div className='description-sexe'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill='#ff30c4' d="M64 176a112 112 0 1 1 224 0A112 112 0 1 1 64 176zM208 349.1c81.9-15 144-86.8 144-173.1C352 78.8 273.2 0 176 0S0 78.8 0 176c0 86.3 62.1 158.1 144 173.1V384H112c-17.7 0-32 14.3-32 32s14.3 32 32 32h32v32c0 17.7 14.3 32 32 32s32-14.3 32-32V448h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H208V349.1z" /></svg>
                            <p>Femelle</p>
                          </div>
                        }
                      </div>
                      <div className='info-bottom'>
                        <p>{chat[0].pelage}</p>
                        <p>{getAge(chat[0].date_naiss)}</p>
                      </div>
                    </div>
                    <div className='img-chat-mobile'>
                      <Carousel infiniteLoop>
                        {imgList && imgList.map((fadeImage, index) => (
                          <div key={index}>
                            <img src={`/uploads/${fadeImage.image}`} />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                    <div className='info-description'>
                      <h1>Description</h1>
                      <p>{chat[0].description}</p>
                    </div>
                  </div>
                </div>
                <div className='info-formulaire'>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSeiuEZbbnCJnNle9HwpEJIYrb953RA2SlqbguSVEYa-TjsCrg/viewform?usp=sf_link" target="_blank">
                    <button>
                      <div className='adopt-button'>
                        <span className="material-symbols-rounded">
                          assignment
                        </span>
                        <p>Lien vers le formulaire d'adoption</p>
                      </div>
                    </button>
                  </a>
                </div>
              </div>
            )
              :
              <div className='chat-not-loaded'>
                <Loader />
                <div className='chats-list-notloaded'>
                  <ChatInfoPlaceholder></ChatInfoPlaceholder>
                </div>
              </div>
        }
        <Footer></Footer>
      </div>
    </>

  )
}

export default ChatInfo