import React, { useEffect, useState } from 'react';

import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import moment from 'moment'
import '../CSS/ComingSoon.css';
import axios from 'axios';

const ComingSoon = () => {

    return (
        <section className='soon'>
            {/* overlay */}
            <div className='overlay'></div>
            <video src="/videos/launching.mp4" autoPlay loop muted playsInline />
            <div className='soon__content'>
                <h1>Les Bibichoux de St. Martin</h1>
                <h3>
                    Le site sera disponible bientôt.
                </h3>
            </div>
        </section>
    );
};

export default ComingSoon;