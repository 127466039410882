import React from 'react'
import ContentLoader from "react-content-loader";
import '../CSS/ChatPlaceholder.css'
const ChatPlaceholder = () => {
    const mystyle = {
        display: "flex",
        justifyContent: "center",
        textAlign: 'center'
    };

    return (
        <div className='chatPlaceholder'>
            <ContentLoader viewBox="0 0 280 315" height={315} width={280} speed={3} style={mystyle}>
                <rect x="3" y="3" rx="10" ry="10" width="280" height="200" />
                <rect x="6" y="210" _rx="0" ry="0" width="280" height="20" />
                <rect x="4" y="240" _rx="0" ry="0" width="239" height="20" />
                <rect x="4" y="270" rx="0" ry="0" width="274" height="20" />
                <rect x="4" y="300" rx="0" ry="0" width="274" height="20" />
            </ContentLoader>
        </div>
    )
}

export default ChatPlaceholder