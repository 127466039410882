import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import '../CSS/Association.css'
import { EffectCards } from "swiper";
import "swiper/css";
import "swiper/css/effect-cards";
import Footer from './Footer';
import GoBackButton from './GoBackButton';
import { Link } from 'react-router-dom';
import axios from 'axios';
import StepsProcess from './StepsProcess';

const Association = () => {

    const [allChats, setAllChats] = useState(null)
    const [chatsAdopte, setChatsAdopte] = useState(null)

    const missions = [
        {
            id : "sauvetage-soins",
            title: "Sauvetage et soins des chats errants ou abandonnés",
            description: "L'une de nos principales missions est de sauver les chats errants, abandonnés ou maltraités et de les offrir des soins vétérinaires adéquats. Cela inclus la vaccination, la stérilisation, le traitement des maladies et des blessures, la nourriture et l'hébergement.",
            image: "/images/sauvetage.jpg"
        },
        {
            id : "proliferation",
            title: "Lutter contre la prolifération des chats",
            description: "Nous travaillons souvent à stériliser et castrer les chats errants, afin de contrôler leur population, de prévenir la surpopulation de chats dans les rues et par conséquent l'augmentation des abandons de chats.",
            image: "/images/proliferation.jpg"
        },
        {
            id : "nouvelle-vie",
            title: "Offir une nouvelle vie à nos loulous",
            description: "Nous cherchons à placer les chats recueillis dans des foyers aimants et permanents. Pour cela, nous vérifions les antécédents et les références des adoptants potentiels, et s'assurons que les chats sont stérilisés avant leur adoption.",
            image: "/images/nouvelle-vie.jpg"
        }
    ]

    const getAllChats = async () => {
        const res = await axios.get(process.env.REACT_APP_API_URL + '/nbChats')
        if (res.status === 200) {
            setAllChats(res.data)
        }
    }

    const getChatsAdopte = async () => {
        const res = await axios.get(process.env.REACT_APP_API_URL + '/chatsAdopte')
        if (res.status === 200) {
            setChatsAdopte(res.data)
        }
    }

    useEffect(() => {
        getAllChats()
        getChatsAdopte()
    }, [])


    return (
        <div className='association'>
            <GoBackButton location="/"></GoBackButton>
            <div className='presentation'>
                <div className='text-presentation'>
                    <h1>Les Bibichoux de St. Martin</h1>
                    <p>Nunc vero inanes flatus quorundam vile esse quicquid extra urbis pomerium nascitur aestimant praeter orbos et caelibes, nec credi potest qua obsequiorum diversitate coluntur homines sine liberis Romae.Oportunum est, ut arbitror, explanare nunc causam, quae ad exitium praecipitem Aginatium inpulit iam inde a priscis maioribus nobilem, ut locuta est pertinacior fama. nec enim super hoc ulla documentorum rata est fides.</p>
                </div>
            </div>
            <div className='missions'>
                <h1>Nos missions</h1>
                <ul className="cards">
                    {
                        missions.map((mission) => (
                            <li key={mission.id} className="cards__item">
                                <div className="card">
                                    <div className="card__image">
                                        <img src={mission.image}></img>
                                    </div>
                                    <div className="card__content">
                                        <div className="card__title">{mission.title}</div>
                                        <p className="card__text">{mission.description}</p>
                                    </div>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className='social-networks'>
                <h1>Nos réseaux sociaux</h1>
                <p>Venez suivre nos aventures sur nos réseaux sociaux afin d'être informé des dernières actualités concernant l'association. </p>
                <ul>
                    <li>
                        <a href="https://www.facebook.com/profile.php?id=100087436623565" target="_blank">
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                            <span> - Facebook</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/lesbibichouxdestmartin/" target="_blank">
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                            <span> - Instagram</span>
                        </a>
                    </li>
                    <li>
                        <a href='https://www.tiktok.com/@lesbibichouxdestmartin' target="_blank">
                            <i className="fa-brands fa-tiktok" aria-hidden="true"></i>
                            <span> - Tiktok</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className='chiffres'>
                <div className="custom-shape-divider-bottom-1670422563">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
                <div className='chiffres-container'>
                    <h1>Quelques chiffres </h1>
                    <div className='chiffres-stats'>
                        <div className='chiffres-chats-adopte'>
                            <div className='img-chats-adopte'></div>
                            <div className='text-chats-adopte'>
                                <div className='nb-chats-adopte'>
                                    <p>{chatsAdopte && chatsAdopte.length}</p>
                                </div>
                                <div>
                                    <p>Chats adoptés</p>
                                </div>
                            </div>
                        </div>
                        <div className='chiffres-chats-charge'>
                            <div className='img-chats-charge'></div>
                            <div className='text-chats-charge'>
                                <div className='nb-chats-charge'>
                                    <p>{allChats}</p>
                                </div>
                                <div>
                                    <p>Chats pris en charge</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='but-adopte'>
                        <Link to="/asso/adopte">
                            <button><p>Voir nos adoptés</p></button>
                        </Link>
                    </div>
                </div>
                <div className="custom-shape-divider-top-1670426874">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
            </div>
            <div className='fonctionnement'>
                <h1>Processus d'adoption</h1>
                <div className='fonctionnement-container'>
                    <div className='process-mobile'>
                        <StepsProcess vertical={true}></StepsProcess>
                    </div>
                    <div className='process'>
                        <StepsProcess vertical={false}></StepsProcess>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </div>
    )
}

export default Association